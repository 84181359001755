function setLanguagePreference(lang) {
  localStorage.setItem("language", lang);
}

function redirectToPreferred() {
  const prefferedLanguage = localStorage.getItem("language");

  if (prefferedLanguage === "en" && !window.location.href.includes("html")) {
    window.location.href = `${window.location.href}/index-en.html`;
  }
}

redirectToPreferred();
